import './App.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './contents/About';
import Contact from './contents/Contact';
import Home from './contents/Home';
import Skills from './contents/Skills';
import CV from './contents/CV';

function App() {
  return (
    <Router>
      <div className="parent-container">
        <Navbar />
        <div className="site-main">
          <div className="site-main-inner">
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/skills">
              <Skills />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/cv">
              <CV />
            </Route>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
