import React, { Component } from 'react';
import i18n from './../translations/i18n';
import './Contact.scss';

class Contact extends Component {
  render() {
    return (
      <div className="content-section">
        <div className="content-section-inner">
          <h1 className="main-title">{i18n.t('contact_me')}</h1>
          <p>&nbsp;</p>
          <div className="vcard">
            <dl className="dl dl-vertical">
              <dt>E-mail:</dt>
              <dd>
                <a href="mailto:info@pnag.pt">
                  <i className="icon fa fa-envelope"></i> info@pnag.pt
                </a>
              </dd>

              <dt>&nbsp;</dt>
              <dd></dd>

              <dt>LinkedIn:</dt>
              <dd>
                <a href="https://www.linkedin.com/in/pnag">
                  <i className="icon fa fa-linkedin"></i> pnag
                </a>
              </dd>

              <dt>Github:</dt>
              <dd>
                <a href="https://github.com/pnag90">
                  <i className="icon fa fa-github"></i> pnag90
                </a>
              </dd>

              <dt>Facebook:</dt>
              <dd>
                <a href="https://facebook.com/pnag6">
                  <i className="icon fa fa-facebook"></i> pnag6
                </a>
              </dd>

              <dt>Twitter:</dt>
              <dd>
                <a href="https://twitter.com/pnag90">
                  <i className="icon fa fa-twitter"></i> @pnag90
                </a>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
