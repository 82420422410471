import React, { Component } from 'react';
import avatar_cv from '../img/avatar-cv.jpg';
// import i18n from './../translations/i18n';
import './CV.scss';

class CV extends Component {
  render() {
    return (
      <div className="content-section">
        <div className="cv-page">
          <div className="cv-resume">
            <div className="cv-page-inner">
              <div className="cv-section cv-header p-contact h-card">
                <div className="cv-header-avatar">
                  <img src={avatar_cv} alt="Pedro" className="avatar" />
                </div>
                <div className="cv-header-text">
                  <h1 className="cv-header-name p-name">Pedro Gonçalves</h1>
                  <h2 className="cv-header-subname p-title">Software Engineer</h2>
                  <div className="cv-header-lead p-note">
                    8 years of experience in front-end and back-end software engineering.
                    <br />
                    Ambitious and communicative.
                    <br />
                  </div>
                </div>
                <div className="cv-header-meta">
                  <div className="cv-header-meta-left">
                    <div className="cv-header-meta-row cv-header-meta-location">
                      Porto, Portugal
                    </div>
                  </div>
                  <div className="cv-header-meta-right">
                    <div className="cv-header-meta-row">
                      <a href="https://pnag.pt/" rel="me" className="u-url">
                        www.pnag.pt
                      </a>
                    </div>
                    <div className="cv-header-meta-row">
                      <a href="mailto:info@pnag.pt" className="u-email">
                        info@pnag.pt
                      </a>
                    </div>
                    <div className="cv-header-meta-row">
                      <a href="https://www.linkedin.com/in/pnag" rel="me">
                        linkedin.com/in/pnag
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cv-section">
                <h3 className="cv-section-title">Skills</h3>
                <div className="cv-section-content cv-section-content--indented">
                  <ul className="mt-0">
                    <li>
                      Back-end: Kotlin, Java, JavaScript, SQL databases, Oracle PL/SQL, in-memory databases
                      (Redis, Memcached), Elasticsearch, Spring Framework, OAuth authentication,
                      REST, microservice and event-based architectures.
                    </li>
                    <li>Front-end: JavaScript, TypeScript, Angular, Redux, React, Ionic.</li>
                    <li>Cloud: AWS and Azure.</li>
                    <li>
                      DevOps: configured and maintained web servers through SSH, Nginx, Docker; Jenkins; CI/CD processes.
                    </li>
                    <li>
                      Infrastructure management (as Ingrastructre-as-Code), systems administration
                    </li>
                    <li>
                      Attentive to UI/UX details and high code quality. Fan of unit tests,
                      functional programming, typed interfaces, and DDD.
                    </li>
                    <li>
                      Can help in or take over: project management, estimation and ownership, tech
                      recruitment, client negotiations.
                    </li>
                    <li>Open to evolving other languages and paradigms.</li>
                  </ul>
                </div>
              </div>

              <div className="cv-section">
                <h3 className="cv-section-title">Experience</h3>
                <div className="cv-section-content">
                  <div className="cv-timeline">
                  <div className="cv-timeline-row p-experience h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">Bosch</div>
                        <div className="cv-timeline-date">Jan 2023 - Today</div>
                        <a
                          href="https://www.boschsecurity.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="cv-timeline-website"
                        >
                          Bosch Security Systems
                        </a>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <h4 className="cv-timeline-position p-name">Senior DevOps Engineer</h4>
                        <p>
                          Management of Development Infrastructure. Built services through combination 
                          of Terraform to provision infrastructure (modules and providers). Automation 
                          of build and deployent process that reduced human
                          error and speed up production processes, aswell maintanace of CI/CD pipelines 
                          for code deployment.
                        </p>
                        <ul>
                          <li>Cloud infrastracture: AWS, Azure</li>
                          <li>CI/CD pipelines and shared libraries: Jenkins, GitHub Actions</li>
                          <li>System Administration</li>
                          <li>Networking</li>
                          <li>Containerization</li>
                        </ul>
                      </div>
                    </div>

                    <div className="cv-timeline-row p-experience h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">Bosch</div>
                        <div className="cv-timeline-date">May 2021 - Dec 2022</div>
                        <a
                          href="https://www.boschsecurity.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="cv-timeline-website"
                        >
                          Bosch Security Systems
                        </a>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <h4 className="cv-timeline-position p-name">Senior Cloud Software Developer</h4>
                        <p>
                          Design and development of AWS solutions for speech-control API's (Amazon Alexa and Google Assistant)
                          Backend for mobile apps
                        </p>
                        <ul>
                          <li>Kotlin (server side development)</li>
                          <li>AWS</li>
                          <li>Jenkins (CI/CD), Terraform (IaC), puml (DaC)</li>
                          <li>Scrum</li>
                        </ul>
                      </div>
                    </div>

                    <div className="cv-timeline-row p-experience h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">First Solutions Com</div>
                        <div className="cv-timeline-date">Dec 2016 - April 2021</div>
                        <a
                          href="http://www.first-global.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="cv-timeline-website"
                        >
                          First Solutions
                        </a>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <h4 className="cv-timeline-position p-name">Senior Software Engineer</h4>
                        <p>
                          Built and maintained a SaaS platform used by a large number of healthcare
                          companies in Portugal.
                        </p>
                        <ul>
                          <li>
                            Led and coordinated the front-end strategy to move the front-end stack
                            from AngularJS to Angular 2, TypeScript, Webpack, RxJS and Redux.
                          </li>
                          <li>
                            Helped migrate the back-end for a new architecture using Spring and
                            Hibernate.
                          </li>
                          <li>
                            Developed and released a cross-platform mobile app. (in Ionic Framework
                            for both Android and iOS)
                          </li>
                        </ul>
                        <p>
                          Led design and development of an mobile hybrid application,{' '}
                          <a
                            href="https://www.yesdiet.pt/YES-diet#dnn_ctr632_HtmlModule_lblContent"
                            target="_blank"
                            rel="noreferrer"
                            alt="YESdiet"
                            className="cv-timeline-website"
                          >
                            YES!diet
                          </a>
                          .
                        </p>
                        <ul>
                          <li>Angular, Ionic</li>
                          <li>Java J2EE, Spring Boot, Spring Data, nodeJs</li>
                          <li>Oracle Databases, Elasticsearch</li>
                          <li>Actively helped manage, estimate and scope out the project.</li>
                        </ul>
                      </div>
                    </div>
            
                    <div className="cv-timeline-row p-experience h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">First Solutions Com</div>
                        <div className="cv-timeline-date">Nov 2014 - Nov 2016</div>
                        <a
                          href="http://www.first-global.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="cv-timeline-website"
                        >
                          First Solutions
                        </a>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <h4 className="cv-timeline-position p-name">Junior Software Engineer</h4>
                        <p>
                          Worked on a SaaS, improving the current version, also creating new
                          infrastractures and funcionalities.
                        </p>
                        <ul>
                          <li>
                            Created new modules and functionalities, designing them from the data
                            layer to the front-end as full-stack.
                          </li>
                          <li>
                            Created a new nodeJs server as secondary server of the main onde (Java).
                            Managing authentication, sockets and rest requests.
                          </li>
                          <li>
                            Development of a mobile and web platform (HTML5) that allows users of
                            certain institutions to access the archive of queries, biometric records
                            and request new queries;
                          </li>
                          <li>Led internships.</li>
                        </ul>
                      </div>
                    </div>

                    <div className="cv-timeline-row p-experience h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">First Solutions Com</div>
                        <div className="cv-timeline-date">Nov 2013 - Oct 2014</div>
                        <a
                          href="http://www.first-global.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="cv-timeline-website"
                        >
                          First Solutions
                        </a>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <h4 className="cv-timeline-position p-name">Trainee Developer</h4>
                        <ul>
                          <li>
                            Created and launched a large module for the company main application
                            (ePM) for the Emergency Area. Application focused on the management of
                            the entire process of an Emergency Service, from admission, through
                            medical screening and consultation, to the discharge process;
                          </li>
                          <li>
                            Implemented custom JS and Java widgets, for specifics functionalities
                            requested for certain clients.
                          </li>
                          <li>
                            Development of a mobile and web platform (HTML5) that allows users of
                            certain institutions to access the archive of queries, biometric records
                            and request new queries;
                          </li>
                          <li>Helped raise an investment from outside VCs.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cv-section">
                <h3 className="cv-section-title">Education</h3>
                <div className="cv-section-content">
                  <div className="cv-timeline">
                    <div className="cv-timeline-row p-education h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">Porto University</div>
                        <div className="cv-timeline-date">2008 - 2014</div>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <div className="cv-timeline-position p-name">
                          Integrated Master in Network and Information Systems Engineering
                        </div>
                        <p>
                          {' '}
                          <a
                            href="https://sigarra.up.pt/fcup/en/web_page.inicial"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Faculdade de Ciências da Universidade do Porto (FCUP)
                          </a>
                        </p>
                        <ul>
                          <li>Specialization in Communication Networks</li>
                          <li>Specialization in Software Engineering and Information Systems</li>
                        </ul>
                      </div>
                    </div>

                    <div className="cv-timeline-row p-education h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">ZonaVerde</div>
                        <div className="cv-timeline-date">May 2018 - Jun 2018</div>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <div className="cv-timeline-position p-name">Project Management Course</div>
                        <ul>
                          <li>
                            {' '}
                            Identify the skills necessary for the effective management of a project,
                          </li>
                          <li>Structure and plan a project,</li>
                          <li>Define realistic and measurable objectives for the projects,</li>
                          <li>
                            Estimate costs, resources and dates for the different stages of the
                            project,
                          </li>
                          <li>
                            Use the best techniques and methodologies associated with Project
                            Management.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="cv-timeline-row p-education h-event">
                      <div className="cv-timeline-side">
                        <div className="cv-timeline-company">ZonaVerde</div>
                        <div className="cv-timeline-date">Feb 2013 - May 2013</div>
                      </div>
                      <div className="cv-timeline-body p-summary">
                        <div className="cv-timeline-position p-name">
                          Initial Pedagogical Training of Trainers
                        </div>
                        <ul>
                          <li>
                            With the obtaining of the CCP of Trainer (former CAP), I am qualified to
                            exercise the activity of trainer in his area, fitting into training
                            projects.
                          </li>
                          <li>
                            In addition to improving my communication skills, I am able to plan,
                            develop and execute training actions and also to use and enhance
                            distance learning platforms (online).
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cv-section">
                <h3 className="cv-section-title">Other perks</h3>
                <div className="cv-section-content cv-section-content--indented">
                  <ul>
                    <li>I speak native Portuguese, conversational English and understand basic Spanish.</li>
                    <li>I consider myself an organized and self-motivated person, also a team player.</li>
                    <li>In my free time, I like to travel, movies, friends and do sports, mostly runnning, play football and padel.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CV;
