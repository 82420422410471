import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTypingEffect from 'react-typing-effect';
import i18n from './../translations/i18n';
import './Home.scss';

class Home extends Component {
  render() {
    return (
      <div className="content-section home-screen">
        <div className="content-section-inner">
          <div className="home-heading"> Hi, I 'm Pedro,</div>
          <div className="home-subheading">
            a software engineer specialized in
            <br />
            both <strong>frontend</strong> and <strong>backend</strong>.
            <ReactTypingEffect className="typingeffect" staticText="" speed={100} />
          </div>
          <div className="home-buttons">
            <div role="button" className="button button-outline button-white">
              <Link to={'skills'}> {i18n.t('skills')} </Link>
            </div>
            <div role="button" className="button button-outline button-white">
              <Link to={'cv'}> {i18n.t('my_cv')} </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
