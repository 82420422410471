import React, { Component } from 'react';
import i18n from './../translations/i18n';
import './Navbar.scss';
import avatar from '../img/avatar.png';
import Navitem from './Navitem';
import Social from './Social';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      NavItemActive: '',
    };
  }

  activeitem = (x) => {
    if (this.state.NavItemActive.length > 0) {
      document.getElementById(this.state.NavItemActive).classList.remove('active');
    }
    this.setState({ NavItemActive: x }, () => {
      document.getElementById(this.state.NavItemActive).classList.add('active');
      this.toggleClass();
    });
  };

  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  render() {
    return (
      <aside className="site-sidebar">
        <div className="site-sidebar-inner h-card">
          <a href="/" rel="me" className="person u-url">
            <div className="person-avatar u-photo">
              <img src={avatar} alt="" className="avatar" />
            </div>
            <div className="person-content">
              <h1 className="person-title p-name"> Pedro Gonçalves </h1>
              <h2 className="person-subtitle p-job-title p-note"> Software Engineer </h2>
            </div>
          </a>
          <nav
            className={
              this.state.active ? 'block main-navigation extended' : 'block main-navigation'
            }
          >
            <div className="navigation-extend-bar">
              <Social />
              <div
                role="button"
                className="button navigation-extend-button"
                onClick={this.toggleClass}
              >
                <i className="fa fa-bars"></i>
              </div>
            </div>
            <div className="navigation-extendable">
              <ul>
                <Navitem item={i18n.t("about_me")} tolink="/about" activec={this.activeitem}></Navitem>
                <Navitem item={i18n.t("skills")} tolink="/skills" activec={this.activeitem}></Navitem>
                <Navitem item={i18n.t("contact_me")} tolink="/contact" activec={this.activeitem}></Navitem>
              </ul>
              <ul>
                <Navitem item={i18n.t("my_cv")} tolink="/cv" activec={this.activeitem}></Navitem>
              </ul>
            </div>
          </nav>
          <div className="block block-social">
            <div className="block-title">{i18n.t("get_in_touch")}</div>
            <div className="block-content">
              <Social />
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
export default Navbar;
