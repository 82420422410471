import React, { Component } from 'react';
import i18n from './../translations/i18n';
import './Skills.scss';

class Skills extends Component {
  render() {
    return (
      <div className="content-section">
        <div className="content-section-inner">
          <h1 className="main-title">{i18n.t('my_offer')}</h1>
          <p>
            <strong>
              A <span className="color-primary">full-stack software engineer</span> with over
              <span className="color-primary"> 10 years of experience</span>.
            </strong>
          </p>
          <p>
            <strong>
              I have maintained, developed and launched multiple projects from scratch
            </strong>
            , carrying the development of its' backend and frontend codebases.
          </p>
          <p>
            My current toolset includes <strong>Java</strong>, Spring, <strong>Kotlin</strong>, <strong>NodeJs</strong>,{' '}
            Angular, TypeScript, Redux, React, Ionic, Python, GO, Gradle, Groovy, Nginx, Docker, Jenkins{' '}
            <strong>Terraform</strong> and all the other various frameworks, libraries and technologies related to them.
          </p>

          <hr></hr>

          <h2 className="color-primary">
            <i className="fa fa-edit"></i>
            Front-end Engineering
          </h2>
          <p>
            <strong>I specialize in applications written in both Angular and React.</strong>
          </p>
          <p> My current experience and skills in front-end includes:</p>
          <ul>
            <li>
              js/ts development: bootstraping, refactoring, improving the structure,
              reliability, build setup;
            </li>
            <li>
              full integration of front-end development using tools like{' '}
              <a href="https://webpack.js.org/" target="_blank" rel="noreferrer">
                Webpack
              </a>{' '}
              with features like automatic code reload, code minifications, multiple environments
              support, CI/CD, Storybook components library, automated testing of accessibility,
              server-side render and visual snapshots,
            </li>
            <li>good sense of design and UX,</li>
            <li>
              huge experience in almost all of the JS ecosystems, by having used in the past many
              libraries like Angular, Ionic Framework, RxJS, Next.js, React, Redux,
              Redux-Observable, Backbone, jQuery, Lodash/Underscore and many else.
            </li>
            <li>
              ... and even <strong>mobile app development</strong> with the use of{' '}
              <a href="http://ionicframework.com/" target="_blank" rel="noreferrer">
                {' '}
                Ionic Framework
              </a>{' '}
              , with both cordova and capacitor.
            </li>
          </ul>

          <hr></hr>

          <h2 className="color-primary">
            <i className="fa fa-code"></i>
            Back-end Engineering
          </h2>
          <p>
            <strong>
              In back-end development, my current stack involves{' '}
              <a href="https://www.oracle.com/java/technologies/java-ee-glance.html" target="_blank" rel="noreferrer">
                Java
              </a>{' '}
              with{' '}
              <a href="https://spring.io/" target="_blank" rel="noreferrer">
                Spring Framework
              </a>
            </strong>,
              also 
              {' '}
            <strong>
              <a href="https://kotlinlang.org/docs/getting-started.html" target="_blank" rel="noreferrer">
                Kotlin
              </a>
            </strong>
            , alternatively{' '}
            <a href="https://nodejs.org/" target="_blank" rel="noreferrer">
              NodeJS
            </a>
            , and introducing{' '}
            <strong>
            <a href="https://go.dev/solutions/#use-cases" target="_blank" rel="noreferrer">
              Go
            </a>
            </strong>
            .
          </p>
          <p>
            Nearly every application I have worked in the past had the back-end (or part of) done
            also by me. In order to improve the development speed, performance and reliability, I
            have changed languages and frameworks already multiple times, also as a evolution
            process and constant learning.
          </p>
          <p>What I can do:</p>
          <ul>
            <li>development of backends,</li>
            <li>splitting the back-end into separate domains and microservices,</li>
            <li>
              cooperation with APIs, remote data synchronizations, cloud servers, asynchronous
              workers,
            </li>
            <li>
              using different types of databases (like{' '}
              <a href="https://www.oracle.com/database/" target="_blank" rel="noreferrer">
                Oracle PL/SQL
              </a>
              ,{' '}
              <a href="https://www.mysql.com/" target="_blank" rel="noreferrer">
                MySQL
              </a>
              ,{' '}
              <a href="http://www.elasticsearch.org/" target="_blank" rel="noreferrer">
                Elasticsearch
              </a>
              ,{' '}
              <a href="http://redis.io/" target="_blank" rel="noreferrer">
                Redis
              </a>
              )
            </li>
            <li>
              dividing the servers into different machine nodes / docker containers; database
              sharding; load balancing,
            </li>
            <li>
              refactoring existing applications, by improving code readibility, separating concerns
              into separate functions/classes/modules, taking the business logic out from your
              request/response layer into separate modules (
              <a href="https://en.wikipedia.org/wiki/Domain-driven_design" target="_blank" rel="noreferrer">
                DDD
              </a>
              ), and moving the app architecture into an event-based one
            </li>
            <li>configure and manage vps/dedicated servers</li>
            <li>management of CI/CD pipelines using Jenkins or GitHub Actions</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Skills;
