import React, { Component } from 'react';
import './About.scss';

class About extends Component {
  render() {
    return (
      <div className="content-section">
        <div className="content-section-inner">
          <h1 className="main-title">Biography</h1>
          <p>&nbsp;</p>
          <p>
            Currently I work at Bosch Security Systems (Ovar, Portugal) as a Senior DevOps Engineer, working in the Smart Home devision.
          </p>
          <p>
            Aside from my daily job, I have also created some projects, as freelance and developer
            enthusiast, as mobile aplications, websites (personal, entreprise and ecommerce).
          </p>
          <p>My interests lie in web/mobile/cloud application enginnering and development.</p>
        </div>
      </div>
    );
  }
}
          /*<p>
            Currently I work at First Solutions (Portugal) as a Software Engineer, where I
            work in a team with the propuse on deliviring healthcare software, which is utilized
            daily by thousands of costumers.
          </p>*/
export default About;
