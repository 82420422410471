import React, { Component } from 'react';
import './Social.scss';

class Social extends Component {
  render() {
    return (
      <div className="social-icons">
        <a
          rel="me"
          href="mailto:info@pnag.pt"
          className="button button-icon u-email"
          title="mail: info@pnag.pt"
        >
          <i className="fa fa-envelope"> </i>
        </a>
        <a
          rel="me"
          href="https://www.linkedin.com/in/pnag"
          className="button button-icon"
          title="linkedin: pnag"
        >
          <i className="fa fa-linkedin-square"> </i>
        </a>
        <a
          rel="me"
          href="https://github.com/pnag90"
          className="button button-icon"
          title="github: pnag90"
        >
          <i className="fa fa-github-square"> </i>
        </a>
        <a
          rel="me"
          href="https://facebook.com/pnag6"
          className="button button-icon"
          title="facebook: pnag6"
        >
          <i className="fa fa-facebook-square"> </i>
        </a>
        <a
          rel="me"
          href="https://twitter.com/pnag90"
          className="button button-icon"
          title="twitter: @pnag90"
        >
          <i className="fa fa-twitter-square"> </i>
        </a>
      </div>
    );
  }
}

export default Social;
